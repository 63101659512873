import React from "react"

import {Helmet} from "react-helmet";

const ConsumerTermsOfService = () => {
    return (
        <div className="legal">
            <Helmet>
                <title>Consumer Terms of Service - Shopdm Pay </title>
            </Helmet>
            <div>
                <div className="h1">Consumer Terms of Service</div>
                <div>Last updated: 10th October 2024</div>
                <div className="margin-top-1em">
                Hi There! We’re very happy that you want to use Shopdm Pay.  Before you engage with us, we want to make sure you understand how we operate, the commitments that we make to you, and what we expect from you. Please read through this Consumer Terms of Service agreement and reach out to us at pay@shopdm.store if you have any questions. 
                </div>
                <div className="h2">1. About this Consumer Terms Of Service Agreement</div>
                <div>
                    <div>
                        These Consumer Terms of Service and any policies referenced within <b>(the “Agreement”)</b> are a legal agreement between Dom Software Ltd T/A Shopdm Pay <b>("we", "our", "us", “Dom Software Ltd” or "Shopdm Pay")</b> and you <b>(“you” or “your”)</b>, the individual who uses any of our products or services <b>(“Services”)</b>, whether for your personal use or on behalf of a business or organization.
                    </div>
                    <div className="margin-top-05em">
                        This Agreement governs your use of Shopdm Pay’s Services. By using any of Shopdm Pay’s Services or by opening a Shopdm Pay Account, you confirm that you accept this Agreement, in its most current form as posted on our Website or App. For avoidance of doubt, this Agreement includes our Privacy Policy, Security Policy, Refund Policy and any specific terms within this document that limit our liability.  
                    </div>
                </div>
                <div className="h2">2. Who we are</div>
                <div>
                    <div>Shopdm Pay is a service operated by Dom Software Ltd, a company incorporated and registered in the Commonwealth of Dominica, with company number is 2019/C0074.</div>
                    <div className="margin-top-05em">Our registered office is at Top Floor, 20 Bath Road, Roseau, Dominica. This office is not publicly open to you or the general public. If you have any questions or if you would like to contact us for any other reason, please email us at pay@shopdm.store.</div>
                </div>
                <div className="h2">3. Our Services under this Agreement</div>
                <div>
                    <div>We are a Payment Services Provider and we act as such by developing, hosting and offering our Services to you, to allow you to pay businesses registered with Shopdm Pay <b>(“Merchants”)</b> via the Internet. Our Services are in compliance with the local laws and regulations.</div>
                    <div className="margin-top-05em">
                        <div>Our Services consist of all the services we provide now and in the future. At present these include:</div>
                        <ol>
                            <li>Online Invoice Payments</li>
                            <li>In-Person QR Code Payments</li>
                            <li>Hosted Product Payments</li>
                            <li>Integrated Website Payments</li>
                        </ol>
                    </div>
                    <div>
                        Whenever you use our Services we will collect information about you to determine your identity. The information collected about you may be explicitly requested, for example in regards to your phone number or email, or it may be automatically collected via your use of the Services, such as your IP Address or Device Fingerprint. Any combination of this information constitutes your Shopdm Pay Account <b>(“Account” or “Shopdm Pay Account”)</b> and any use of our Services which results in us collecting such information about you constitutes your opening of a Shopdm Pay Account. This information will be handled in accordance with our Privacy Policy.
                    </div>
                </div>
                <div className="h2">4. Your Eligibility To Use Our Services</div>
                <div>
                    <div>You may only enter into this Agreement if you meet the following criteria and are able to enter into a legally binding contract in the country in which you reside. If any of these conditions is not met, you must not use our Services.</div>
                    <ol>
                        <li>You must be an individual and at least 18 years old to use our Services.</li>
                        <li>If you represent a business, entity or charitable organization when you use our Services, you are authorized to transact on behalf of that business, entity or charitable organization.</li>
                        <li>All uses of our Services by you or your Account are considered activities conducted by you. You agree to only use the Services to transact on your own Account and not on behalf of any other person or any entity which you are not authorized to represent. You must not allow others to access or use the Services or your Shopdm Pay Account on your behalf.</li>
                        <li>You may not enter into this Agreement or use any of our Services if you are in any jurisdiction that is the target of government sanctions by any national government.</li>
                        <li>You may not enter into this Agreement or use any of our Services if you represent a business, entity, charitable organization, group or individual that is the target of government sanctions, blocked or prohibited by any authority or any national government. For example, but not limited to, those applied by the U.S. Department of the Treasury Office of Foreign Assets Control.</li>
                    </ol>
                </div>
                <div className="h2">5. How To Safely Use Our Services</div>
                <div>
                    <ol>
                        <li>Where possible, do business with entities that you trust. We recommend you use our Services to pay trusted and verified businesses and third parties that are known to you or that you have already done business with. You should be cautious when paying Merchants which you don’t know and have never dealt with before.</li>
                        <li>
                            <div>
                                When accessing your Shopdm Pay Account or any of the Services, you should do the following, at minimum, to keep yourself safe:
                            </div>
                            <ol className="list-style-lower-alpha">
                                <li>Change your password regularly and ensure that it is not reused for other online accounts.</li>
                                <li>Never share your login details with anyone else.</li>
                                <li>If you suspect that your account may have been accessed by someone else, contact the Shopdm Pay Customer Support Team. We will never ask you for your password.</li>
                                <li>Always use strong passwords, for example passwords that do not include common words and are composed of uppercase and lowercase letters, as well as numbers, and symbols.</li>
                                <li>Set up 2-step authentication, if applicable.</li>
                                <li>Ensure that your email account is secure. This is because your email account may be used to reset your password. Let the Shopdm Pay Customer Support Team know immediately if your email account becomes compromised.</li>
                                <li>Update the App regularly on your device to the latest available version.</li>
                                <li>Update your device’s browser to the latest available version.</li>
                                <li>Maintain your device’s operating systems with regular security updates provided by the operating system provider.</li>
                                <li>Install and maintain the latest anti-virus software on your devices, where applicable.</li>
                                <li>Avoid phishing and other malicious attacks by ensuring that every time you are entering your Shopdm Pay username, password, phone number, credentials and card information that it is only ever on our official App (downloaded from the iOS or Android store) or if via a browser, at https://pay.shopdm.store. Attackers may send emails and SMS messages to lead you to fake login portals that could phish your login information and 2-step authentication details. Similarly, criminals can advertise malicious adverts on search engines that lead to fake websites that impersonate real, trusted businesses, including Shopdm Pay.</li>
                                <li>If you suspect you have become a victim of a scam, contact the Shopdm Pay Customer Support Team as soon as possible.</li>
                            </ol>
                        </li>
                        <li>
                            <div>You agree to never:</div>
                            <ol className="list-style-lower-alpha">
                                <li>Give your login credentials or 2-step authentication details to anyone.</li>
                                <li>Allow anyone else to access your Shopdm Pay Account or profile, or watch you accessing it, including by allowing someone else to take remote control of your device.</li>
                                <li>Do anything which may in any way avoid or compromise the 2-step authentication process.</li>
                            </ol>
                        </li>
                        <li>Contact us immediately, if you suspect your Shopdm Pay Account has been compromised. If you suspect your Account, profile or login credentials have been stolen, lost, or otherwise compromised, you must immediately contact the Shopdm Pay Customer Support Team.</li>
                        <li>Any delays in notifying us of a compromised account, may affect the security of your account and result in losses that you would be responsible for. You must promptly provide us with any information which we request of you or any reasonable assistance we require from you in order to investigate the incident and to secure your account.</li>
                        <li>You are responsible for ensuring that your device and other relevant technology is configured to access our Services. We cannot guarantee that our Services will be free from bugs or viruses.</li>
                    </ol>
                </div>
                <div className="h2">6. How You Can Use Our Services and Systems</div>
                <div>
                    <div>You agree to only use our Services and your Shopdm Pay Account in lawful and constructive ways. You agree not to misuse our Services. You agree not to misuse any of our systems, technological or otherwise. In particular, you agree to the following:</div>
                    <ol>
                        <li>You agree not to use our Services for any fraudulent or unlawful purpose.</li>
                        <li>You agree that all information you provide to us must be complete, accurate and truthful at all times. You also agree to update any information that we hold about you, whenever it changes. You agree that we are not responsible for any loss arising out of your failure to provide complete accurate, truthful and up to date information.</li>
                        <li>We may ask you at any time to confirm the accuracy of your information and/or to provide additional supporting documents, and you agree to comply with these requests and to provide such information in a format acceptable to us.</li>
                        <li>
                            You must not misuse our Services by:
                            <ol className="list-style-lower-alpha">
                                <li>Breaching this Agreement, Additional Document, or any other agreement between you and us or any of our partners.</li>
                                <li>Violating any law, statute, ordinance, or regulation for example, those governing financial services, consumer protections, sale of counterfeit goods or false advertising.</li>
                                <li>Acting in a manner that is defamatory, libelous, threatening or harassing.</li>
                                <li>Providing false, inaccurate, or misleading information.</li>
                                <li>Sending or receiving what we believe to be potentially fraudulently gained funds.</li>
                                <li>Participating in any transaction that may be deemed to be fraudulent by any financial or regulatory authority.</li>
                                <li>Acting in a manner that leads us or any regulatory or banking agency to believe that you may be in non-compliance with any applicable anti-money laundering laws and regulations.</li>
                                <li>Refusing to cooperate in an investigation or to provide confirmation of your identity or any information requested by us.</li>
                                <li>Infringing Shopdm Pay’s Intellectual Property.</li>
                                <li>Attempting to “double dip” during a dispute or claim by receiving or attempting to receive funds from both Shopdm Pay and a third party, for example, a Merchant, bank or card issuer for the same transaction.</li>
                                <li>Abusing a card network dispute resolution process.</li>
                                <li>Fraudulently initiating a card network dispute for any reason which is not deemed to be true by both the card network and us, including, but not limited to, claiming that you did not receive goods or services which you received, or claiming that the goods or services received were not at the expected quality when this is untrue, or claiming that you did not agree to an agreement between yourself and us or one of our Merchants or partners, when you did indeed agree to that agreement.</li>
                                <li>Using our Services in a manner that we, a card network or any other electronic funds transfer network believes to be an abuse of the card system or a violation of card association or network rules.</li>
                                <li>Taking any action that may cause us to lose any of the services from our  payment processors, internet service providers, or our other suppliers or service providers.</li>
                                <li>Harassing, threatening or otherwise being abusive towards our employees, agents, or other customers.</li>
                                <li>Using our Services in a manner that results in or may result in complaints to or about Shopdm Pay.</li>
                                <li>Using our Services in a manner that results in or may result in requests by third parties to invalidate payments made to you.</li>
                                <li>Using our Services in a manner that results in or may result in fees, fines, penalties or any other liability or losses to Shopdm Pay, other customers, third parties or you.</li>
                                <li>Accessing our Services from a country that we are not permitted to operate in.</li>
                                <li>Circumventing this Agreement or any Additional Document or any of our policies or any determinations we take about your Shopdm Pay Account such as temporary or indefinite suspensions or other account holds, limitations or restrictions, including, but not limited to, engaging in the following actions: attempting to open a new or additional Shopdm Pay account(s) or when another of your accounts has a negative balance or has been restricted, suspended, or otherwise limited or opening new or additional Shopdm Pay Accounts using information that is not your own (e.g., phone number, name, address, email address, etc.), or using someone else’s Shopdm Pay Account.</li>
                            </ol>
                        </li>
                        <li>
                            You agree not to misuse our systems by:
                            <ol className="list-style-lower-alpha">
                                <li>introducing any technologically harmful materials to our systems, including but not limited to, viruses, trojans, worms, logic bombs or other materials which are intended to, or have the effect of negatively affecting our systems.</li>
                                <li>Taking any action that results in an unreasonable or disproportionately large load being imposed on our websites, software, systems (including any networks and servers used to provide any of the Services) operated by us or on our behalf, or attacking our Website, App or API with any type of denial-of-service attack.</li>
                                <li>Using an anonymizing proxy; use any robot, spider, other automatic device, or manual process to monitor or copy our websites without our prior written permission; or use any device, software, or routine to bypass our robot exclusion headers.</li>
                                <li>Interfering, disrupting, or attempting to interfere or to gain unauthorized access to our Website, software, API, systems (including any networks and servers used to provide any of the Services) operated by us or on our behalf, any of the Services or other users’ use of any of the Services.</li>
                            </ol>
                        </li>
                        <li>
                            If you misuse any of our Services or systems or if we suspect any violation of any part of this Agreement or if we have concerns about the security of your Account or if we believe you may have been involved with any fraudulent, unlawful or unauthorized use of your Account or the Services or if we are notified of a court order or any other legal process affecting you, we may take several actions to protect Shopdm Pay, its partners, its customers and others. These include but are not limited to the following: 
                            <ol className="list-style-lower-alpha">
                                <li>We may immediately terminate this Agreement.</li>
                                <li>We may immediately close your Shopdm Pay Account without notice, and without penalty to us.</li>
                                <li>We may immediately suspend your Shopdm Pay Account or your access to our Services for an indeterminate period.</li>
                                <li>We may refuse to provide our Services to you or your businesses or affiliates in the future.</li>
                                <li>We may take legal action against you, including seeking damages and reimbursement for losses and fees.</li>
                                <li>We may contact your bank or credit card issuer, other impacted third parties, or law enforcement about your actions and we may disclose any relevant information about you, your Account or your use of our Services.</li>
                                <li>We may update any inaccurate information that you have provided to us.</li>
                                <li>We may refuse to process any of your transactions or, if processed, refuse to remit the funds of any transaction to you or any related Merchant.</li>
                                <li>If the proceeds of any fraudulent transaction have already been remitted to you, we may debit the transaction amount from any balances which you hold with us, and if you do not hold any balance with us from which we can debit for the full amount, you agree to repay the full amount of the transaction to us within 3 days of us requesting payment from you, in the manner that we request it of you.</li>
                                <li>We may hold or freeze any balance that you hold with us, if reasonably needed to protect against any risk which we, at our sole discretion, have determined that we, our partners or customers have been exposed to and the hold may remain in place as long as is reasonably necessary.</li>
                                <li>We may  impose limits on the number, amount or value, individually or in aggregate of payments that you can send via our Services.</li>
                                <li>We may share any information about you, your Account or your use of the Services with any law enforcement, banking partners, or regulatory agencies.</li>
                                <li>We may request additional information from you about yourself, your business, your use of the Services, or your Account, and you agree to provide any requested information within 3 days.</li>
                                <li>We may report any suspected breach of law, regulation, or criminal statute to the relevant law enforcement authorities, in which case we will cooperate with those authorities, including by disclosing your identity to them.</li>
                                <li>We may limit your access to any of our systems, in any way that we see fit.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div className="h2">7. Fees & Pricing</div>
                <div>
                    <div>The following represents our current fee structures and policies. You agree to the following:</div>
                    <ol>
                        <li>Registering for Shopdm Pay is free.</li>
                        <li>Any information or policy associated with our fees or limits may change at any time and at our sole discretion.</li>
                        <li>At all times, any relevant fees will be displayed clearly and transparently to you before you pay for any of the Services.</li>
                        <li>You may or may not incur a fee when you use our Services to pay a Merchant, for example, when you pay a Merchant’s invoice or purchase a product on a Merchant’s website. Whether you pay a fee and the value of the fee you pay is dependent on the fee structure agreed between Shopdm Pay and the Merchant.</li>
                        <li>Our fees may vary depending on the Merchant you are using our Services to pay.</li>
                        <li>At no time will our fees exceed our maximum fee. Our current maximum fee is 7% of the price of the products and services being offered to you by the Merchant.</li>
                        <li>You agree to pay any applicable fees associated with your use of the Services.</li>
                        <li>We may not process your transaction(s) or provide any other Services to you until we have received the relevant fees from you.</li>
                        <li>Our fees are not a surcharge. Our fees are used to cover the operational costs associated with developing, running and maintaining our business, platform and systems.</li>
                        <li>Our fees do not include any fees that a Merchant, your bank, the recipient’s bank or other third party may separately charge.</li>
                        <li>Our consumer fees and merchant fees are exclusive of any taxes, charges, or similar assessments of any nature, including, without limitation, value-added, sales, digital services, stamp, transfer, or withholding taxes, assessable by any jurisdiction or governmental authority <b>(collectively, “Taxes”)</b>.</li>
                        <li>You are responsible for any Taxes which may be due by you because of your use of our Services, and it is your responsibility to collect, report and pay the correct tax to the appropriate tax authority.</li>
                    </ol>
                </div>
                <div className="h2">8. Data Protection</div>
                <div>
                    You agree that we will process your personal data in accordance with our Privacy Policy which can be found here.
                </div>
                <div className="h2">9. Our Intellectual Property Rights</div>
                <div>
                    <div>You agree to the following:</div>
                    <ol>
                        <li>All rights, title and interest in and to any software (including without limitation the Website, the API, and any other systems, developer tools, sample source code, and code libraries), data, materials, content and printed and electronic documentation (including any specifications and integration guides) developed, provided or made available by us or our affiliates to you, including content of the Website, and any and all technology and any content created or derived from any of the foregoing <b>(“Shopdm Pay Materials”)</b> and our Services are the exclusive property of Shopdm Pay and its licensors. The Shopdm Pay Materials and Services are protected by intellectual property rights laws and treaties around the world. All such rights are reserved.</li>
                        <li>While you are using our Services, you may use the Shopdm Pay Materials only for your personal use unless you have received written permission from us and solely as necessary to enjoy our Services. Subject to your compliance with this Agreement and your payment of any applicable fees, Shopdm Pay grants you a revocable, non-exclusive, non-sublicensable, non-transferable, royalty-free limited license to access and/or make personal use of the Shopdm Pay Materials and Services. Any use of the Shopdm Pay Materials and Services not specifically permitted under this Agreement is strictly prohibited. The licenses granted by Shopdm Pay terminate if you do not comply with this Agreement or any other service terms.</li>
                        <li>
                            Unless you have received written permission from us, you may not, and may not attempt to, directly or indirectly
                            <ol className="list-style-lower-alpha">
                                <li>use any of the Shopdm Pay Materials for any commercial purpose or otherwise infringe our intellectual property rights,</li>
                                <li>transfer, sublicense, loan, sell, assign, lease, rent, distribute or grant rights in the Services or the Shopdm Pay Materials to any person or entity.</li>
                                <li>remove, obscure, or alter any notice of any of our trademarks, or other Intellectual Property appearing on or contained within the Services or on any Shopdm Pay Materials.</li>
                                <li>modify, copy, tamper with or otherwise create derivative works of any software included in the Shopdm Pay Materials; or</li>
                                <li>reverse engineer, disassemble, or decompile the Shopdm Pay Materials or the Services or apply any other process or procedure to derive the source code of any software included in the Shopdm Pay Materials or as part of the Services.</li>
                            </ol>
                        </li>
                        <li>You may not copy, imitate, modify, or use our trademarks without our prior written consent. You may use HTML logos or other Materials provided by us for the purpose of directing web traffic to the Services. You may not alter, modify, or change these HTML logos in any way, use them in a manner that mischaracterizes Shopdm Pay or the Services or display them in any manner that implies our sponsorship or endorsement. Further, you may not use our trademarks in connection with any product or service that is not our, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Shopdm Pay.</li>
                    </ol>
                </div>
                <div className="h2">10. Roles, Responsibilities & Dispute Resolution</div>
                <div>
                    <div>
                    When you use our Services there may be multiple parties involved in the transaction. For example, when you use our Services to pay a Merchant’s invoice or when you buy a Merchant’s product via the Services, there will be at least three parties involved: you, the Merchant and us. In the following, we will clarify the roles and responsibilities of each of the respective parties. You agree to the following:
                    </div>
                    <ol>
                        <li>Shopdm Pay does not have any control over, and is not responsible or liable for, the products or services that are paid for via its Services.</li>
                        <li>When you pay a Merchant for goods and/or services using our Services you are entering into a contract with that Merchant to deliver those goods and/or services <b>(the “Merchant Contract”)</b>. You are not entering into a contract with Shopdm Pay. Shopdm Pay’s obligation to you, at all times, will only be to properly run the Services.</li>
                        <li>Merchants who use Shopdm Pay to accept payments are under an obligation to properly and satisfactorily deliver goods and services under any and all of their Merchant Contracts.</li>
                        <li>If you have any concerns in relation to a Merchant Contract then a dispute has arisen <b>(the “Dispute”)</b>. For example, a Dispute may arise if a Merchant has not shipped an item to you after an online purchase or if the item you received varies significantly from what they described, or if you paid the wrong Merchant or if you paid a Merchant the wrong amount. When a Dispute arises, you will contact the Merchant in the first instance and seek to resolve the Dispute. You agree to contact the Merchant and attempt to resolve the Dispute before contacting any other party including, but not limited to, your bank, your card issuer, a card network or Shopdm Pay. You agree to contact the Merchant and attempt to resolve the Dispute before initiating a card network dispute resolution process.</li>
                        <li>If you have contacted the Merchant and you are unable to resolve the Dispute within 3 days then you may escalate the Dispute to a Claim with Shopdm Pay <b>(the “Claim”)</b>. You agree to contact Shopdm Pay in the first instance to seek to resolve the Claim via Shopdm Pay’s dispute resolution process <b>(the “Dispute Resolution Process”)</b>. You agree to contact Shopdm Pay and attempt to resolve the Claim via Shopdm Pay’s Dispute Resolution Process before contacting any other party including, but not limited to, your bank, your card issuer or a card network. You agree to contact Shopdm Pay and attempt to resolve the Claim before initiating a card network dispute resolution process.</li>
                        <li>Upon receiving your instruction to initiate the Dispute Resolution Process, Shopdm Pay will first confirm that you were unable to resolve the Dispute with the Merchant. Next, Shopdm Pay will investigate your Claim by collecting information from you and the Merchant. Then Shopdm Pay will review the information provided and seek to determine how to resolve the Claim and in whose favor the Claim will be awarded. This process will constitute the Dispute Resolution Process.</li>
                        <li>Shopdm Pay will, wherever possible, seek to resolve the Claim within 7 days of receiving all requested information from you and the Merchant.</li>
                        <li>If a Claim is awarded in your favor, and it involves a refund, you will be refunded an amount not more than the full amount which you had paid to the Merchant via our Services under the Merchant Contract. The money will typically be refunded to the original payment method you used for the transaction, a Shopdm Pay balance, or a bank account of your choosing.</li>
                        <li>If the Merchant does not provide all of the requested information within 14 days of you filing the Claim, then Shopdm Pay will automatically award the Claim in your favor.</li>
                        <li>You agree not to initiate a card network dispute during an ongoing Dispute Resolution Process.</li>
                        <li>You agree not to “double-dip” by receiving or attempting to receive funds from both Shopdm Pay and the Merchant, bank or card issuer for the same transaction.</li>
                    </ol>
                </div>
                <div className="h2">11. Termination</div>
                <div>
                    <div>Termination by Us: We may terminate this Agreement, and we may limit, suspend, change, or remove your access to any or all of the Services, including any feature or aspect of the Services, at any time for any reason. If commercially reasonable, we will take reasonable steps to notify you before taking any action that restricts your access to the Services. If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of this Agreement, we may terminate this Agreement at any time without notice to you and accordingly we may terminate your access to the Services and your Shopdm Pay Account.</div>
                    <div className="margin-top-05em">Termination by You: Subject to any product-specific Agreements between you and us, you may terminate any Service at any time and for any reason by terminating the Service or closing or deleting your Shopdm Pay Account by emailing us with such a request at pay@shopdm.store. Termination will be effective on the date that your Account is closed.</div>
                    <div className="margin-top-05em">Effect of Termination. Upon termination, you will not have any further use of or access to the Services or your Shopdm Pay Account. Subject to applicable law, you will also not have any use of or access to any information you submitted through the Services, and all rights granted under this Agreement will end. Termination does not relieve you of your obligations to pay amounts owed to Shopdm Pay, Merchants, or others. Termination does not revoke any third-party payment authorizations. The following provisions will survive even after this Agreement terminates: Arbitration Agreement, Our Intellectual Property Rights, Disclaimer of Warranties, Limitation of Liability, Governing Law and the terms in Miscellaneous.</div>
                </div>
                <div className="h2">12. Indemnification</div>
                <div>
                    You shall defend, indemnify and hold harmless Shopdm Pay and its officers, directors, shareholders, and employees, from and against all claims, suits, proceedings, losses, liabilities, and expenses, whether in tort, contract, or otherwise, that arise out of or relate, including but not limited to attorneys’ fees, in whole or in part arising out of or attributable to any breach of this Agreement or any activity by you in relation to your use of the Services.
                </div>
                <div className="h2">13. Arbitration Agreement</div>
                <div>
                    If you are located in the United States, you agree to our Arbitration Agreement, which requires you to resolve disputes between you and Shopdm Pay on an individual basis through arbitration, prohibits you from maintaining or participating in a class action lawsuit, waives your right to a jury trial, and limits the time in which a claim may be brought.
                </div>
                <div className="h2">14. Disclaimer of Warranties</div>
                <div>
                    <ol>
                        <li>The Services are provided “as-is” and without any representation or warranty, whether express or implied. Shopdm Pay makes no representation or warranty of any kind whatsoever (other than those implied by statute) with respect to the Services or the content, materials, information and functions we make accessible, and specifically disclaim all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement. We do not promise that the Services will be uninterrupted, error-free, or secure.</li>
                        <li>Shopdm Pay does not control or make any warranties regarding the products or services others provide in connection with the Services. In other words, we do not have control over the Merchants or any other third party from which you’re purchasing when using the Services, and we do not promise or imply that the products or services you buy using the Services will work as promised or be safe to use.</li>
                        <li>Some laws limit or prohibit disclaiming the warranties referred to in the previous paragraphs, or impose obligations on us that we can’t eliminate with this Agreement. In those cases, this section (Disclaimer of Warranties) does not restrict, exclude or modify any consumer rights under any applicable law.</li>
                    </ol>
                </div>
                <div className="h2">15. Limitation of Liability</div>
                <div>
                    <div>You agree to the following:</div>
                    <ol>
                        <li>Shopdm Pay shall in no event be liable to you or to any third party, whether in contract, warranty, tort (including negligence) or otherwise, for lost profits or any special, indirect, incidental, punitive, exemplary, liquidated or consequential damages (including without limitation damages for loss of data or loss of business, profit, revenue, goodwill or opportunity) arising out of or in connection with our websites, software, systems (including any networks and servers used to provide any of the Services) operated by us or on our behalf, any of the Services, or this Agreement (however arising, including negligence - even if Shopdm Pay has been advised of the possibility of such damages), unless and to the extent prohibited by law.</li>
                        <li>In particular, Shopdm Pay will not be liable to you or to any third party for any failure to perform our obligations under this Agreement due to a Force Majeure Event. A “Force Majeure Event” is any event beyond the control of Shopdm Pay or its affiliates, including a strike or other labor dispute; labor shortage, stoppage or slowdown; supply chain disruption; embargo or blockade; telecommunication breakdown; power outage or shortage; inadequate transportation service; inability or delay in obtaining adequate supplies; weather; earthquake; fire; flood; act of God; riot; civil disorder; civil or government calamity; epidemic; pandemic; state or national health crisis; war; invasion; hostility (whether war is declared or not); terrorism threat or act; Law; or act of a Governmental Authority.</li>
                        <li>In particular, if you have a dispute with any other Shopdm Pay user, Merchant or otherwise or any third party with whom you transact via the Services, you release Shopdm Pay from all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. This means that you must use caution when dealing with third parties when using our Services.</li>
                        <li>Notwithstanding anything to the contrary in this Agreement, Shopdm Pay’s liability to you for any cause whatsoever and regardless of the form of the action, will at all times be limited to a maximum of XCD $500 (Five Hundred Eastern Caribbean Dollars).</li>
                        <li>You and we agree that the other has relied on the indemnification, arbitration agreement (if applicable), disclaimer of warranties and limitation of liability stated above in entering into this Agreement, the indemnification, arbitration agreement, limitation of liability and disclaimer of warranties are essential to the agreement between you and us under this Agreement, and they will apply to the fullest extent allowed by law.</li>
                        <li>Some laws restrict our ability to disclaim or limit our liability. In those cases, this section does not restrict, exclude or modify any consumer rights under any applicable law.</li>
                    </ol>
                </div>
                <div className="h2">16. Our right to make changes</div>
                <div>
                    We may revise the terms in this Agreement from time to time. We will use reasonable efforts to notify you of material changes to this Agreement in advance of their effectiveness, including by posting notice on the applicable Services or providing notice via an email address associated with you. The revised Agreement will be effective on the date stated in the revised Agreement. By using any part of the Services after any revisions become effective, you agree to those changes. If you do not agree with any changes to this Agreement, you must stop using our Services.
                </div>
                <div className="h2">17. Miscellaneous</div>
                <div>
                    You agree to the following:
                    <ol>
                        <li>This Agreement is between you and us. Except as expressly provided in this Agreement, no other person shall have any rights to enforce any of its terms. Neither of us will need to get the agreement of any other person to end or make any changes to this Agreement.</li>
                        <li>You may not transfer, assign, mortgage, charge, subcontract, declare a trust over or deal in any other manner with any or all your rights and obligations under this Agreement without our prior written consent. We reserve the right to transfer, assign or novate this Agreement or any right or obligation under this Agreement at any time without your consent. This does not affect your rights to close your Shopdm Pay Account.</li>
                        <li>Severability. Each of the paragraphs of this Agreement operates separately. If any court or relevant authority decides that any of them are unlawful or unenforceable, the remaining paragraphs will remain in full force and effect.</li>
                        <li>Enforcement. Even if we delay in enforcing this Agreement, we can still enforce it later. If we delay in asking you to do certain things or taking certain action, it will not prevent us from taking steps against you at a later date.</li>
                        <li>Entire Agreement. This Agreement supersedes and extinguishes all previous agreements between you and Shopdm Pay, whether written or oral, relating to its subject matter.</li>
                    </ol>
                </div>
                <div className="h2">18. Governing Law</div>
                <div>
                    This Agreement is governed by the laws of The Commonwealth of Dominica. Any dispute between you and us in connection with the Services and/or this Agreement shall be brought in the courts of the Commonwealth of Dominica.
                </div>
            </div>
        </div>
    )
}

export default ConsumerTermsOfService