import React from "react";
import contact from "../../config/contact";
import {Link} from "react-router-dom"
import Facebook from "./facebook.png"
import styles from "./Footer.module.css"

const Footer = () => {
    return (
        <div className={styles.container}>
            <div className="blocks">
            <div className="stack">
                <div className='h2'>Get To Know Us</div>
                <div className="spaced"><Link to="/about">About Shopdm</Link></div>
                <div className="spaced">Shopdm is a <a href="http://www.domsoftware.io">Dom Software</a> product</div>
                <div className='h3'>Dom Software</div>
                <div>{contact.companyAddress.line1},</div>
                <div>{contact.companyAddress.city},</div>
                <div className="spaced">{contact.companyAddress.country}</div>
                <div className='h3'>Contact</div>
                <div>General inquiries ? Yes, we answer our emails 😊 - <a href={`mailto:${contact.maintenanceEmail}?subject=Feedback On Shopdm`} target="_blank">{contact.maintenanceEmail}</a></div>
                <div>Order updates or "how does it work" questions ? <a title="call" href={`tel:${contact.operationsPhone}`}>{contact.operationsPhone}</a></div>
                <div>Technical issues ? <a title="call" href={`tel:${contact.maintenancePhone}`}>{contact.maintenancePhone}</a></div>
                <div>Seller support ? <a title="call" href={`tel:${contact.partnersPhone}`}>{contact.partnersPhone}</a></div>
            </div>
            <div className="stack">
                <div className='h2'>Legal</div>
                <Link to="/legal/consumer-terms-of-service">Consumer Terms of Service</Link>
                {/* <Link to="/seller-terms-of-use">Partner Terms Of Use</Link> */}
                <Link to="/legal/privacy">Consumer Data Privacy Policy</Link>
                <Link to="/legal/security">Security Policy</Link>
            </div>
            <div className="stack">
                <div className='h2'>Payment</div>
                <div className="spaced">We accept VISA and MasterCard</div>
                <div className="visaMastercard">
                    <img className="providerLogo" src="https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2FpaymentProviderLogos%2Fvisamastercard%2Fvisa-mastercard.png?alt=media&token=1e23e638-a933-4ab7-af36-1459395113ed"/>
                    <img className="providerLogo" src="https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2FpaymentProviderLogos%2Fvisamastercard%2FVerifiedByVisa80x48.jpg?alt=media&token=51a0a9ae-8eba-4760-89da-ed0de18f9f48"/>
                    <img className="providerLogo" src="https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2FpaymentProviderLogos%2Fvisamastercard%2Fsc-mastercard-securecode66x48.png?alt=media&token=fb467261-077d-4b2d-a0f6-4d6fb4e69caa"/>
                </div>
            </div>
            </div>
            <div className="bottom">
                <div><a href='https://www.facebook.com/Shopdm-107717144270757'><img src={Facebook}/></a></div>
                <div>© {new Date().getFullYear()} Shopdm</div>
            </div>
        </div>
    )
}

export default Footer